import {
  HttpResponse,
  UrlQueryParameter,
  UrlQueryParameterish,
} from '../types/http';
import {err, ok, Result} from './result';
import {isObject} from './validation';

/**
 * Checks if the given argument can be used within the URL
 * @param arg - The argument to validate
 * @returns True if it can be used, false otherwise
 */
export function isValidUrlArgument(arg: unknown) {
  if (typeof arg === 'string') return arg.length > 0;
  else if (typeof arg === 'object' || typeof arg === 'undefined') return false;
  return true;
}

/**
 * Checks if the given HTTP response is valid.
 * @param response - The HttpResponse to validate
 * @returns True if a valid HTTP response, false otherwise
 */
export function isValidHttpResponse(response: HttpResponse<unknown>): boolean {
  if (!response) return false;
  const validHttpCode = response.httpCode === 200;
  const dataExists = !!response.data;

  return validHttpCode && dataExists;
}

/**
 * Packages data into the standard package to mimic a response from the HTTPCore file
 * @param data The data to package
 * @param httpCode The HTTP response this package will have
 * @param message A message to return, if any
 * @returns Data packaged in our standard HTTP response
 */
export function packageResponse<T = unknown>(
  data: T,
  httpCode = 200,
  message = ''
): HttpResponse<T> {
  return {
    data,
    httpCode,
    message,
  };
}

/**
 * Builds an invalid HttpResponse indicating that one of the arguments is malformed
 * @param message - The custom message to include
 * @returns An HttpResponse informing of an incorrectly formatted call
 */
export function invalidArgumentResponse(message: string) {
  return packageResponse(undefined, 400, message);
}

/**
 * Sanitizes the HTTP response data object to make it safe for serialization. It primarily deletes
 *  properties marked as `undefined`, which cannot be serialized to JSON in NextJS
 * @param obj - The object to sanitize for safe serialization. The original object is updated
 * @returns The object that was sanitized
 */
export function sanitizeForSerialization<T>(obj: T): T {
  const objIsScalar = typeof obj !== 'object';
  const objIsNull = obj === null;
  if (objIsScalar || objIsNull) return obj;
  if (Array.isArray(obj)) {
    return obj.map(sanitizeForSerialization) as T;
  }

  const allKeys = Object.keys(obj);
  allKeys
    .filter((key: string) => obj[key] === undefined)
    .forEach((key: string) => delete obj[key]);

  const definedKeys = Object.keys(obj);
  definedKeys.forEach(
    (key: string) => (obj[key] = sanitizeForSerialization(obj[key]))
  );

  return obj;
}

/**
 * Transforms a value into a version that can safely go into the URL
 * @param unsafeParameter - The unsafe URL query parameter
 * @returns A safe URL parameter
 */
export function makeSafeUrlQueryParameter(
  unsafeParameter: UrlQueryParameterish
): UrlQueryParameter {
  if (!Array.isArray(unsafeParameter)) {
    return makeSafeUrlQueryParameterScalar(unsafeParameter);
  }

  return unsafeParameter
    .map(makeSafeUrlQueryParameterScalar)
    .filter(parameter => parameter !== undefined);
}

/**
 * Converts an unknown scalar value into one that can be safely set in the URL
 * @param unsafeParameter - Some scalar value to convert into a value that is safe to put into a URL parameter
 * @returns A string value or undefined
 */
function makeSafeUrlQueryParameterScalar(
  unsafeParameter: string | number | undefined
): string | undefined {
  switch (typeof unsafeParameter) {
    case 'undefined':
    case 'string':
      return unsafeParameter;
    case 'number':
      return unsafeParameter.toString();
    default:
      return undefined;
  }
}

/**
 * Grabs the first valid response from an HttpResponse containing an array in data.
 * Some endpoints return an array of data with one item, so this handles the normal
 * checking that would need to be done to get that single item
 * @param initialResponse - The initial response returned from the server
 * @returns A HttpResponse with the first valid item from the data's array, else a 404 error
 */
export function returnFirstValidResponse<T>(
  initialResponse: HttpResponse<T[]>
): HttpResponse<T> {
  if (!isValidHttpResponse(initialResponse)) {
    return initialResponse as HttpResponse<never>;
  }

  const returnedDataIsNotAnArray = !Array.isArray(initialResponse.data);
  if (returnedDataIsNotAnArray) return packageResponse(undefined, 404);

  const returnedDataIsEmpty = initialResponse.data.length === 0;
  if (returnedDataIsEmpty) return packageResponse(undefined, 404);

  const response = packageResponse(initialResponse.data[0], 200);
  return response;
}

/**
 * Wraps an HTTP call and ensures it runs safely, then converts it into a Result type
 * @param fetcher - The callback function that makes the fetch request
 * @returns A Result containing the data.
 */
export async function safeHttpCall<T>(
  fetcher: () => Promise<HttpResponse<T>>
): Promise<Result<T>> {
  let result: HttpResponse<T>;
  try {
    result = await fetcher();
  } catch (e) {
    return err(e);
  }

  if (!isValidHttpResponse(result)) return err(result.message);
  return ok(result.data);
}
