import {HttpResponse} from '@/lib/types/http';
import http from '@/lib/api/data-explore/httpCore';
import getConfig from '@/lib/config/config';
import {AxiosRequestConfig} from 'axios';
import {WordpressNavigationDto} from '../../types/dto/navigation';
import {createBasicAuthHeader} from '@/lib/utils/auth/basic';
import {
  invalidArgumentResponse,
  isValidUrlArgument,
  packageResponse,
  returnFirstValidResponse,
} from '@/lib/utils/http';
import {getWordpressApiProxy} from '@/lib/api/proxyPaths';
import {isServer} from '@/lib/utils/environment';
import {WordpressStaffDto} from '../../types/dto/staff';
import {WordpressFooterDto} from '../../types/dto/footer';
import {WordpressMediaPageDto} from '../../types/dto/media/page';
import {WordpressPageDto} from '../../types/dto/page';
import {WordpressFunderLogoDto} from '../../types/dto/media/funderLogo';
import {FeaturedResourceDto} from '../../types/dto/resource';
import {MOCK_FEATURED_RESOURCE_DTOS} from '../../data/mock';
import getNextConfig from 'next/config';
import {isNonEmptyString} from '@/lib/utils/validation';
import {CategoryDto} from '../../types/dto/category';
import {NewsSearchOptions} from '../../types/news';
import {UserDTO} from '../../types/user';
import {CategoryPostType} from '../../types/category';
import {EventsSearchOptions} from '../../types/events';

/**
 * Fetches the navigation for a given archive
 * @param archive - The archive to fetch navigation for
 * @returns An HttpResponse containing the navigation, if found
 */
export async function getWordpressNavigation(
  archive: string
): Promise<HttpResponse<WordpressNavigationDto>> {
  if (!isValidUrlArgument(archive)) {
    return invalidArgumentResponse(
      'An invalid archive was provided to "getWordpressNavigation"'
    );
  }

  const headers = getWordpressHeaders();
  const url = `/${archive}/wp-json/icpsr/v1/options/navigation`;
  return http.get(url, headers);
}

/**
 * Grabs the footer information for a given archive
 * @param archive - The archive to fetch footer information for
 * @returns An HttpResponse containing the footer information, if found
 */
export async function getWordpressFooter(
  archive: string
): Promise<HttpResponse<WordpressFooterDto>> {
  if (!isValidUrlArgument(archive)) {
    return invalidArgumentResponse(
      'An invalid archive was provided to "getWordpressNavigation"'
    );
  }

  const headers = getWordpressHeaders();
  const url = `/${archive}/wp-json/icpsr/v2/options/footer`;
  return http.get(url, headers);
}

/**
 * Fetches the section navigation based on the provided value
 * @param value - The value of the section_navigation_selector
 * @returns An HttpResponse containing the navigation data
 */
export async function getSectionNavigation(
  value: string
): Promise<HttpResponse<WordpressNavigationDto>> {
  if (!isNonEmptyString(value)) {
    return invalidArgumentResponse(
      'An invalid value was provided to "getSectionNavigation"'
    );
  }

  const headers = getWordpressHeaders();
  const url = `/wp-json/custom/v1/section-navigation/${value}`;
  return http.get(url, headers);
}

/**
 * Gets the Wordpress page content for a given archive and page
 * @param archive - The archive to fetch page content for
 * @param pageSlug - The specific page to fetch content for
 * @returns An HttpResponse containing the page data
 */
export async function getWordpressPage(
  archive: string,
  pageSlug: string
): Promise<HttpResponse<WordpressPageDto>> {
  if (!isValidUrlArgument(archive)) {
    return invalidArgumentResponse(
      'An invalid archive was provided to "getWordpressPage"'
    );
  }

  if (!pageSlug) {
    return invalidArgumentResponse(
      'An invalid page slug was provided to "getWordpressPage"'
    );
  }

  const headers = getWordpressHeaders();
  const url = `/${archive}/wp-json/custom/v1/page-route?path=${pageSlug}`;
  return http.get(url, headers);
}

/**
 * Fetches all staff for a given archive
 * @param archive - The archive to fetch staff from
 * @returns An HttpResponse containing an array of staff members
 */
export async function getAllStaff(
  archive: string
): Promise<HttpResponse<WordpressStaffDto[]>> {
  if (!archive || typeof archive !== 'string') {
    return invalidArgumentResponse(
      'An invalid archive was provided to "getAllStaff"'
    );
  }

  const headers = getWordpressHeaders();
  const url = `/${archive}/wp-json/wp/v2/staff_member`;
  return http.get(url, headers);
}

/**
 * Fetches image media from a given WordPress archive by media ID
 * @param archive - The archive from which to fetch the image
 * @param mediaId - The ID of the media item to fetch
 * @returns An HttpResponse containing the image data
 */
export async function getWordpressMedia(
  archive: string,
  mediaId: number | string
): Promise<HttpResponse<WordpressMediaPageDto>> {
  if (!isValidUrlArgument(archive)) {
    return invalidArgumentResponse(
      'An invalid archive was provided to "getWordpressMedia"'
    );
  }

  if (!isValidUrlArgument(mediaId)) {
    return invalidArgumentResponse(
      'An invalid media ID was provided to "getWordpressMedia"'
    );
  }

  const headers = getWordpressHeaders();
  const url = `/${archive}/wp-json/wp/v2/media/${mediaId}`;
  return http.get(url, headers);
}

/**
 * Gets the Wordpress logo
 * @returns An HttpResponse containing the logo data
 */
export async function getWordpressLogo(
  slug: string
): Promise<HttpResponse<WordpressFunderLogoDto[]>> {
  if (!slug) {
    return invalidArgumentResponse(
      'An invalid slug was provided to "getWordpressLogo"'
    );
  }
  const headers = getWordpressHeaders();
  const url = `/shared/wp-json/wp/v2/funder_logos/?slug=${slug}`;

  return http.get(url, headers);
}

/**
 * Fetches a featured resource for the given data type and ID
 * @param dataType - The data type to fetch the featured resource for
 * @param id - The ID of the data type
 * @returns An HTTP response with the featured resource DTO
 */
export async function getFeaturedResource(
  dataType: 'study' | 'collection',
  id: number | string
): Promise<HttpResponse<FeaturedResourceDto[]>> {
  const config = getNextConfig();
  if (
    config.publicRuntimeConfig?.featureFlags?.enableFeaturedResourceApi !==
    'true'
  ) {
    return packageResponse([MOCK_FEATURED_RESOURCE_DTOS[0]]);
  }
  let url = '/shared/wp-json/icpsr/v1/featured_resource';

  if (dataType === 'study') url += '/study';
  else if (dataType === 'collection') url += '/collection';
  else {
    return invalidArgumentResponse(
      'An invalid method was provided to "getFeaturedResource"'
    );
  }

  if (!id) {
    return invalidArgumentResponse(
      'An invalid ID was provided to "getFeaturedResource"'
    );
  }

  url += `?id=${id}`;
  const headers = getWordpressHeaders();

  return http.get(url, headers);
}

/**
 * Fetches the resources for the given data type and ID
 * @param dataType - The data type to fetch the resources for
 * @param id - The ID of the data type
 * @returns An HTTP response with an array of featured resource DTOs
 */
export async function getResourcesAndTraining(
  dataType: 'study' | 'collection',
  id: number
): Promise<HttpResponse<FeaturedResourceDto[]>> {
  const config = getNextConfig();
  if (
    config.publicRuntimeConfig?.featureFlags?.enableFeaturedResourceApi !==
    'true'
  ) {
    return packageResponse(MOCK_FEATURED_RESOURCE_DTOS);
  }
  let url = '/shared/wp-json/icpsr/v1/resource_training';
  if (dataType === 'study') url += '/study';
  else if (dataType === 'collection') url += '/collection';
  else {
    return invalidArgumentResponse(
      'An invalid method was provided to "getFeaturedResource"'
    );
  }

  if (!id) {
    return invalidArgumentResponse(
      'An invalid ID was provided to "getResourcesAndTraining"'
    );
  }

  const headers = getWordpressHeaders();
  url += `?id=${id}`;

  return http.get(url, headers);
}

export async function fetchSidebarContent(slug: string, archive: string) {
  if (!isNonEmptyString(slug)) {
    return invalidArgumentResponse(
      'An invalid slug was provided for the sidebar'
    );
  }
  const url = `${archive}/wp-json/custom/v1/section-navigation/${slug}`;
  const headers = getWordpressHeaders();
  return await http.get(url, headers);
}

/**
 * Fetches the event post for a specific slug. Returns 404 if not found
 * @param slug - The slug of the specific post to fetch
 * @returns An HTTP Response containing a WordpressPageDto on success
 */
export async function fetchEventPost(
  slug: string
): Promise<HttpResponse<WordpressPageDto>> {
  if (!isNonEmptyString(slug)) {
    return invalidArgumentResponse(
      'An invalid slug was provided to fetchEventPost'
    );
  }

  const url = `/shared/wp-json/wp/v2/events?slug=${slug}`;
  const headers = getWordpressHeaders();
  const response: HttpResponse<WordpressPageDto[]> = await http.get(
    url,
    headers
  );
  return returnFirstValidResponse(response);
}

/**
 * Fetches all events for a specific archive, if passed. Otherwise, fetches all events. Returns 404 if not found
 * @param archive - The archive (category) ID from which to get events
 * @returns An HTTP Response containing a WordpressPageDto on success
 */
export async function fetchEvents(
  options?: EventsSearchOptions
): Promise<HttpResponse<WordpressPageDto[]>> {
  const headers = getWordpressHeaders();
  const url = '/shared/wp-json/wp/v2/events';
  const parameters = {
    orderby: options.sortBy || 'event_start',
    order: options.sortOrder || 'asc',
    per_page: options.perPage || 2,
    categories: filtersToParameter(options.categories),
    tags: filtersToParameter(options.tags),
  };

  headers.params = parameters;

  return http.get(url, headers);
}

/**
 * Fetches the FAQ post for a specific slug. Returns 404 if not found
 * @param slug - The slug of the specific post to fetch
 * @returns An HTTP Response containing a WordpressPageDto on success
 */
export async function fetchFaq(
  slug: string
): Promise<HttpResponse<WordpressPageDto>> {
  if (!isNonEmptyString(slug)) {
    return invalidArgumentResponse('An invalid slug was provided to fetchFaq');
  }

  const url = `/shared/wp-json/wp/v2/faq?slug=${slug}`;
  const headers = getWordpressHeaders();
  const response: HttpResponse<WordpressPageDto[]> = await http.get(
    url,
    headers
  );
  return returnFirstValidResponse(response);
}

/**
 * Fetches the news post for a specific slug. Returns 404 if not found
 * @param slug - The slug of the specific post to fetch
 * @returns An HTTP Response containing a WordpressPageDto on success
 */
export async function fetchNewsPost(
  slug: string
): Promise<HttpResponse<WordpressPageDto>> {
  if (!isNonEmptyString(slug)) {
    return invalidArgumentResponse(
      'An invalid slug was provided to fetchNewsPost'
    );
  }

  const url = `/shared/wp-json/wp/v2/news?slug=${slug}`;
  const headers = getWordpressHeaders();
  const response: HttpResponse<WordpressPageDto[]> = await http.get(
    url,
    headers
  );
  return returnFirstValidResponse(response);
}

/**
 * Fetches the staff member for a specific slug. Returns 404 if not found
 * @param slug - The slug of the specific post to fetch
 * @returns An HTTP Response containing a WordpressPageDto on success
 */
export async function fetchStaffMember(
  archive: string,
  slug: string
): Promise<HttpResponse<WordpressPageDto>> {
  if (!isNonEmptyString(archive)) {
    return invalidArgumentResponse(
      'An invalid archive was provided to fetchStaffMember'
    );
  }

  if (!isNonEmptyString(slug)) {
    return invalidArgumentResponse(
      'An invalid slug was provided to fetchStaffMember'
    );
  }

  const url = `/${archive}/wp-json/wp/v2/staff_member?slug=${slug}`;
  const headers = getWordpressHeaders();
  const response: HttpResponse<WordpressPageDto[]> = await http.get(
    url,
    headers
  );
  return returnFirstValidResponse(response);
}
/**
 * Fetches user based on userId
 * @param userId integer userId
 * @returns An HttpResponse with a UserDTO
 */
export async function fetchUser(
  userId: number
): Promise<HttpResponse<UserDTO>> {
  const url = `/shared/wp-json/wp/v2/users/${userId}`;
  const headers = getWordpressHeaders();
  return http.get(url, headers);
}

/**
 * Fetches all shared news categories
 * @returns An HttpResponse with an array of CategoryDtos
 */
export async function fetchNewsCategories(): Promise<
  HttpResponse<CategoryDto[]>
> {
  const url = '/shared/wp-json/wp/v2/news-categories';
  const headers = getWordpressHeaders();
  return http.get(url, headers);
}

/**
 * Fetches shared news category name by ID
 * @param category The ID for the category
 * @returns An HttpResponse with an array of CategoryDtos
 */
export async function fetchNewsCategory(
  category: number
): Promise<HttpResponse<CategoryDto[]>> {
  const url = `/shared/wp-json/wp/v2/news-categories/${category}`;
  const headers = getWordpressHeaders();

  try {
    const response = await http.get(url, headers);
    return response.data.name;
  } catch (error) {
    console.error(`Error fetching category ${category}:`, error);
  }
}
/**
 *
 * @param archive string for archive from URL
 * @returns list of non news post categories from wordpress
 */
export async function fetchArchivePostCategories(
  archive: string
): Promise<HttpResponse<CategoryDto[]>> {
  const url = `/${archive}/wp-json/wp/v2/categories`;
  const headers = getWordpressHeaders();
  return http.get(url, headers);
}

/**
 * Fetches Archive categories for a specific post type
 * @param postType - The specific post type to fetch for
 * @returns An HttpResponse with an array of CategoryDtos
 */
export async function fetchArchiveCategories(
  postType: 'news' | 'events'
): Promise<HttpResponse<CategoryDto[]>> {
  const url = `/shared/wp-json/wp/v2/categories?post_type=${postType}`;
  const headers = getWordpressHeaders();
  return http.get(url, headers);
}

/** fetches all non news posts by category id (not archiveId)
 * @param archive stirng from url for current archive
 * @param categoryId array of ids that can be searched for inclusive post results
 */
export async function fetchArchivePostsByCategory(
  archive: string,
  categoryId: number[] | number
): Promise<HttpResponse<WordpressPageDto[]>> {
  const url = `/${archive}/wp-json/wp/v2/posts?categories=${categoryId}`;
  const headers = getWordpressHeaders();
  return http.get(url, headers);
}

export async function fetchArchivePosts(
  archive: string
): Promise<HttpResponse<WordpressPageDto[]>> {
  const url = `/${archive}/wp-json/wp/v2/posts`;
  const headers = getWordpressHeaders();
  return http.get(url, headers);
}

/**
 * Fetches tags for a specific post type
 * @param postType - The specific post type to fetch for
 * @returns An HttpResponse with an array of CategoryDtos
 */
export async function fetchTags(
  postType: CategoryPostType
): Promise<HttpResponse<CategoryDto[]>> {
  const url = `/shared/wp-json/wp/v2/tags?post_type=${postType}`;
  const headers = getWordpressHeaders();
  return http.get(url, headers);
}

export async function fetchNews(
  options: NewsSearchOptions
): Promise<HttpResponse<WordpressPageDto[]>> {
  const headers = getWordpressHeaders();
  const url = '/shared/wp-json/wp/v2/news';
  const parameters = {
    orderby: options.sortBy || 'date',
    order: options.sortOrder || 'desc',
    per_page: options.perPage || 10,
    page: options.page || 1,
    categories: filtersToParameter(options.categories),
    'news-categories': filtersToParameter(options['news-categories']),
    tags: filtersToParameter(options.tags),
  };

  headers.params = parameters;

  return http.get(url, headers);
}

function filtersToParameter(filters: number[]): string | undefined {
  const filtersAreNotGiven = !Array.isArray(filters) || filters.length === 0;
  if (filtersAreNotGiven) return undefined;
  return filters.join(',');
}

const COMMON_HEADERS = {'Content-Type': 'application/json'};

/**
 * Gets the headers for wordpress calls depending on the environment, if this is the client or the server.
 * @returns Headers for safely calling the Wordpress endpoint
 */
export function getWordpressHeaders(): AxiosRequestConfig<unknown> {
  if (isServer()) return getServerHeaders();
  return getClientHeaders();
}

/**
 * Builds the Wordpress API headers for calls from a server environment
 * @returns An AxiosRequestConfig object
 */
function getServerHeaders(): AxiosRequestConfig<unknown> {
  const config = getConfig();

  const {host, username, password} = config.wordpressApi;
  const authorization = createBasicAuthHeader(username, password);
  return {
    baseURL: host,
    headers: {...COMMON_HEADERS, Authorization: authorization},
  };
}

/**
 * Builds the Wordpress API headers for calls from a client environment
 * @returns An AxiosRequestConfig object
 */
function getClientHeaders() {
  return {
    baseURL: getWordpressApiProxy(),
    headers: {...COMMON_HEADERS},
  };
}

export const __testing__ = {
  getWordpressHeaders,
};
