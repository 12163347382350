/**
 * Creates a basic auth header from a given username and password
 * @param username - The username to encode
 * @param password - The password to encode
 * @returns A string usable with basic auth. If invalid, returns undefined
 */
export function createBasicAuthHeader(
  username: string,
  password: string
): string | undefined {
  if (!username || typeof username !== 'string') {
    return undefined;
  }
  if (!password || typeof password !== 'string') {
    return undefined;
  }

  const authString = `${username}:${password}`;
  let encodedAuth: string;
  // ? Should to/from base64 be a function?
  try {
    encodedAuth = Buffer.from(authString).toString('base64');
  } catch (e: unknown) {
    return undefined;
  }
  return `Basic ${encodedAuth}`;
}
