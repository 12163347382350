import getConfig from 'next/config';
import {ArchiveTheme} from '../types/archive';
import {normalize} from './strings';

/**
 * Determines if the given archive is valid
 * @param archive The archive to validate
 * @returns True if the given archive is valid; false otherwise
 */
export function isValidArchive(archive: string[] | string | undefined) {
  if (!archive) return false;

  let singleArchive: string;
  if (Array.isArray(archive)) {
    if (archive.length === 0) return false;
    singleArchive = archive[0];
  } else {
    singleArchive = archive;
  }
  if (singleArchive.startsWith('_')) return false;
  const validArchives = [
    'icpsr',
    'nacda',
    'nanda',
    'dsdr',
    'hmca',
    'nadac',
    'nahdap',
    'nacjd',
    'sbeccc',
    'pcodr',
    'cfda',
    'aera',
    'aea',
    'weai',
    'jeh',
    'psid',
    'cghe',
  ];
  const cleanedArchive = singleArchive.trim().toLowerCase();

  return validArchives.indexOf(cleanedArchive) >= 0;
}

/**
 * Builds the appropriate CSS class for a given archive to provide its themes.
 * Classes will have the format `archive-icpsr`, where `icpsr` is the given archive.
 * @param archive - The archive slug received from the URL
 * @returns A class for the specific archive
 */
export function buildArchiveClassName(archive: string | undefined) {
  const archiveIsEmpty = typeof archive !== 'string' || !archive;
  if (archiveIsEmpty) return 'archive-icpsr';

  const normalizedArchive = normalize(archive);

  return `archive-${normalizedArchive}`;
}

/**
 * Determines the theme for the given archive slug
 * @param archiveSlug - The current archive slug, as given in the URL. Ex: 'nanda'
 * @returns An ArchiveTheme enum
 */
export function getArchiveTheme(archiveSlug: string): ArchiveTheme {
  const noArchiveIsGiven = !archiveSlug || typeof archiveSlug !== 'string';

  if (noArchiveIsGiven) return ArchiveTheme.Icpsr;
  if (archiveSlug.toLowerCase().trim() === 'icpsr') return ArchiveTheme.Icpsr;
  return ArchiveTheme.Neutral;
}

/**
 * Builds the design system CSS URL
 * @param archive - The archive currently rendered
 * @returns A string containing the CSS URL; in the case of failure, an empty string
 */
export function getArchiveThemeStylesheet(archiveTheme: ArchiveTheme): string {
  const config = getConfig();
  const {icpsrTheme, neutralTheme} =
    config?.publicRuntimeConfig?.designSystem || {};

  switch (archiveTheme) {
    case ArchiveTheme.Icpsr:
      return icpsrTheme ?? '';
    case ArchiveTheme.Neutral:
      return neutralTheme ?? '';
    default:
      return '';
  }
}
