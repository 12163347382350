import getConfig from 'next/config';

/**
 * Gets the proxy path for accessing archive-api through archive-ui middleware
 */
export function getArchiveApiProxy(): string {
  const basePath = getBasePath();
  return `${basePath}/api/archive-api`;
}

/**
 * Gets the proxy path for accessing statsnap-api through archive-ui middleware
 */
export function getStatsnapApiProxy(): string {
  const basePath = getBasePath();
  return `${basePath}/api/statsnap-api`;
}

/**
 * Determines the proxy path for Wordpress API calls through archive-ui middleware
 */
export function getWordpressApiProxy(): string {
  const basePath = getBasePath();
  return `${basePath}/api/wordpress`;
}

/**
 * Grabs the base path from the Next Config, handling any undefined values
 * @returns A string containing the base path, or an empty string if not found
 */
function getBasePath(): string {
  const config = getConfig();
  const basePath = config?.publicRuntimeConfig?.basePath ?? '';
  return basePath;
}
