export function getConfig() {
  return {
    site: {
      env: process.env.ENV,
      nodeEnv: process.env.NODE_ENV,
      deploymentEnv: process.env.PUBLIC_DEPLOYMENT_ENV,
      port: process.env.SERVER_PORT,
      basePath: process.env.PUBLIC_BASE_PATH,
      url: process.env.SITE_URL,
      adminEmail: process.env.ADMIN_EMAIL,
      tlsEnabled: process.env.TLS_ENABLED,
      tlsDir: process.env.TLS_DIR,
      tlsKeyPath: process.env.TLS_KEY_PATH,
      tlsKeyPassphrase: process.env.TLS_KEY_PASSPHRASE,
      tlsCertPath: process.env.TLS_CERT_PATH,
    },

    api: {
      version: process.env.API_VERSION,
      basePath: process.env.PUBLIC_STATSNAP_API_BASE_PATH,
      proxyBasePath: process.env.PUBLIC_STATSNAP_API_PROXY_BASE_PATH,
      host: process.env.API_HOST,
      baseHealthPath: process.env.API_BASE_HEALTH_PATH,

      auth: {
        domain: process.env.ARCHIVE_UI_OAUTH_ARCHIVE_DOMAIN,
        scope: {
          admin: process.env.OAUTH_API_SCOPE_ADMIN,
          maintainer: process.env.OAUTH_API_SCOPE_MAINTAINER,
          user: process.env.OAUTH_API_SCOPE_USER,
        },
        client: {
          id: process.env.ARCHIVE_UI_OAUTH_ARCHIVE_CLIENT_ID,
          secret: process.env.ARCHIVE_UI_OAUTH_ARCHIVE_CLIENT_SECRET,
        },
      },
    },

    archiveApi: {
      version: process.env.API_VERSION,
      basePath: process.env.PUBLIC_ARCHIVE_API_BASE_PATH,
      proxyBasePath: process.env.PUBLIC_ARCHIVE_API_PROXY_BASE_PATH,
      host: process.env.ARCHIVE_API_HOST,
      baseHealthPath: process.env.API_BASE_HEALTH_PATH,

      auth: {
        domain: process.env.ARCHIVE_UI_OAUTH_ARCHIVE_DOMAIN,
        scope: {
          admin: process.env.OAUTH_API_SCOPE_ADMIN,
          maintainer: process.env.OAUTH_API_SCOPE_MAINTAINER,
          user: process.env.OAUTH_API_SCOPE_USER,
        },
        client: {
          id: process.env.ARCHIVE_UI_OAUTH_ARCHIVE_CLIENT_ID,
          secret: process.env.ARCHIVE_UI_OAUTH_ARCHIVE_CLIENT_SECRET,
        },
      },
    },
    solr6: {
      host: process.env.SOLR6_QUERY_SERVER,
      username: process.env.SOLR6_QUERY_USERNAME,
      password: process.env.SOLR6_QUERY_PASSWORD,
    },
    wordpressApi: {
      host: process.env.WORDPRESS_API_HOST,
      username: process.env.WORDPRESS_API_USERNAME,
      password: process.env.WORDPRESS_API_PASSWORD,
    },

    featureFlags: {
      enableNewAuth: process.env.FLAG_ENABLE_NEW_AUTH === 'true',
      disableCognitoCache: process.env.FLAG_DISABLE_COGNITO_CACHE === 'true',
    },

    usageStatisticsApi: {
      usageStatisticsUrl: process.env.USAGE_STATISTICS_URL,
    },
  };
}

export default getConfig;
