import {HttpResponse} from '@/lib/types/http';
import axios, {AxiosError, AxiosResponse} from 'axios';
import logging from '../../utils/logging';

const axiosHttp = axios.create();
const logger = logging.logger('httpCore.ts');
axiosHttp.interceptors.response.use(
  (response: AxiosResponse): Promise<any> => {
    if (!response || response.status !== 200) {
      return Promise.reject(response);
    }
    const packagedResponse: HttpResponse<unknown> = {
      data: response.data,
      httpCode: response.status,
      message: '',
      headers: response.headers,
    };
    return Promise.resolve(packagedResponse);
  },
  (error: AxiosError) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      let message = `statusCode: ${error.response.status}, message: ${error.message}`;
      if (error.request?.method) {
        message += `, ${error.request?.method} from: ${error.request?.host}${error.request?.path}`;
      }

      logger('server')('error', message);

      const packagedResponse: HttpResponse<unknown> = {
        data: null,
        httpCode: error.response.status,
        message: JSON.stringify(error.response.data),
        headers: undefined,
      };
      return packagedResponse;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      logger('server')('error', `statusCode: ${-1}, message: ${error.message}`);
      const packagedResponse: HttpResponse<unknown> = {
        data: null,
        httpCode: -1,
        message: error.message,
        headers: undefined,
      };
      return packagedResponse;
    } else {
      logger('server')('error', `statusCode: ${0}, message: ${error.message}`);
      const packagedResponse: HttpResponse<unknown> = {
        data: null,
        httpCode: 0,
        message: error.message,
        headers: undefined,
      };
      // Something happened in setting up the request that triggered an Error
      return packagedResponse;
    }
    //return Promise.reject(error);
  }
);

export default axiosHttp;
