/**
 * Normalizes a string (or scalar value) into a standardized format for comparison by
 * removing leading/trailing spaces and setting all letters to lowercase
 * @param text The text to normalize
 */
export function normalize(text: string | number | undefined): string {
  if (text === undefined) return '';
  let normalText = typeof text === 'string' ? text : text.toString();
  normalText = normalText.trim();
  normalText = normalText.toLowerCase();
  return normalText;
}

export function convertStringToKebabCase(text: string): string {
  if (!text) return '';
  return text.toLowerCase().trim().replace(/\s+/g, '-');
}

/**
 * Converts a given string to a normalized key for use in loops
 * @param text The text to normalize
 */
export function toKey(text: string | number): string {
  let key = normalize(text);
  key = key.replace(/[^\s\d\w-]+/g, ''); // Removes everything not a letter, number, dash, underscore, or space
  key = key.trim(); // Removing starting or ending characters may result in a leading/trailing space
  key = key.replace(/\s+/g, '-'); // Changes spaces to a single dash
  return key;
}
