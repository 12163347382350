import {FeaturedResourceDto} from '../types/dto/resource';

/** Mock DTO data for the resources and training & feature resource endpoint */
export const MOCK_FEATURED_RESOURCE_DTOS: FeaturedResourceDto[] = [
  {
    ID: '1',
    title: 'Additional Study Materials',
    featured_content: `
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint sed eius
        quaerat officiis hic modi repellendus cum velit dignissimos,
        accusantium ullam eligendi aliquam vitae vel sapiente. Accusantium
        vero eius commodi?
      </p>
      <p>
        Beatae quaerat incidunt fuga enim aliquam omnis? In culpa explicabo
        quas architecto tempora ipsum enim! Nam blanditiis eveniet hic,
        asperiores voluptatibus impedit molestiae quod, corrupti, atque natus
        perspiciatis excepturi deserunt.
      </p>
    `,
    category: 'Internal resources',
    study_numbers: [],
    collection_numbers: [],
  },
  {
    ID: '2',
    title: 'Anchored in Data Reality',
    featured_content: `
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste in
        expedita blanditiis voluptatem earum inventore saepe rerum quibusdam a
        voluptates cupiditate, maiores sequi quaerat fugiat vero dignissimos!
        Reprehenderit, accusamus soluta.
      </p>
    `,
    category: 'Webinars',
    study_numbers: [],
    collection_numbers: [],
  },
  {
    ID: '3',
    title: 'How to Set Up a SPSS file',
    featured_content: `
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tenetur
        animi eum quam totam, magni officia libero aperiam ipsa dolores cum
        veniam quas pariatur ex nulla cumque. Vel provident quos iste!
      </p>
    `,
    category: 'External resources',
    study_numbers: [],
    collection_numbers: [],
  },
  {
    ID: '4',
    title: 'Ten Ways to Use Our Data',
    featured_content: `
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corporis
        ratione, vel est architecto cum, commodi distinctio culpa cupiditate
        accusantium eius illo, animi quam adipisci! Molestias quam ratione
        illo doloribus explicabo.
      </p>
    `,
    category: 'Internal resources',
    study_numbers: [],
    collection_numbers: [],
  },
];
