import * as winston from 'winston';
import {isServer} from './environment';

const logLevel = process.env.LOGGING_LEVEL || 'info';
const options = {
  console: {
    level: logLevel,
    handleExceptions: true,
    format: winston.format.combine(
      winston.format.padLevels(),
      winston.format.prettyPrint({colorize: true}),
      winston.format.printf(
        i =>
          `${new Date()
            .toISOString()
            .replace('T', ' ')
            .replace('Z', '')} ${i.level.toUpperCase()}  ${i.message}`
      )
    ),
  },
};

// instantiate a new Winston Logger with the settings defined above
const log = winston.createLogger({
  transports: [new winston.transports.Console(options.console)],
  exitOnError: false, // do not exit on handled exceptions
});

type LogType = 'debug' | 'info' | 'warn' | 'error';

// TODO - refactor this to be easier to test, if there's a way to
// remove the triple nesting here that would be best.
export function logger(module: string) {
  function funcLogger(functionName: string) {
    function messageLogger(logType: LogType, message: string) {
      isomorphicLog(module, functionName, logType, message);
    }
    return messageLogger;
  }
  return funcLogger;
}

/**
 * Posts a log to the console using the appropriate method for the current environment
 * @param moduleName - The name of the module being logged
 * @param functionName - The name of the function being logged
 * @param logType - The type of log to post
 * @param message - The message to print
 */
function isomorphicLog(
  moduleName: string,
  functionName: string,
  logType: LogType,
  message: string
) {
  const fullMessage = `[${moduleName} - ${functionName}] ${message}`;

  if (isServer()) {
    log[logType](fullMessage);
    return;
  }

  if (typeof console[logType] !== 'function') return;

  console[logType](fullMessage);
}

/**
 * Safely parses an error from a catch statement
 * @param error - An error from a catch statement
 * @returns The error as a string
 */
export function parseError(error: unknown) {
  if (error instanceof Error) return error.message;
  if (typeof error === 'string') return error;
  return '';
}

export const __testing__ = {
  isomorphicLog,
};

export default {
  log: log,
  logger: logger,
};
